import Swiper from 'swiper';
import Choices from 'choices.js';
// import Forms from './forms';
import throttle from 'lodash.throttle';
import Velocity from 'velocity-animate';
import { Masks } from './masks';
import Ajax from './includes/ajax';

const base__url = document.getElementById('base__url').innerHTML;

Masks.init();

const HomeCarouselContainer = document.querySelector('[data-carousel="home"]');

if (HomeCarouselContainer) {

  const HomeCarousel = new Swiper(HomeCarouselContainer, {
    speed: 400,
    navigation: {
      nextEl: HomeCarouselContainer.querySelector('.swiper-button-next'),
      prevEl: HomeCarouselContainer.querySelector('.swiper-button-prev'),
    },
    pagination: {
      el: HomeCarouselContainer.querySelector('.swiper-pagination'),
      clickable: true
    },
    spaceBetween: 0

});
}

const NewsCarouselContainer = document.querySelector('[data-carousel="news"]');

if (NewsCarouselContainer) {

  const NewsCarousel = new Swiper(NewsCarouselContainer, {
    speed: 400,
    slidesPerView: 3,
    navigation: {
      nextEl: document.querySelector('.swiper-button-next--news'),
      prevEl: document.querySelector('.swiper-button-prev--news'),
    },
    spaceBetween: 30

});
}

const SocialCarouselContainer = document.querySelector('[data-carousel="social"]');

if (SocialCarouselContainer) {

  const SocialCarousel = new Swiper(SocialCarouselContainer, {
    speed: 400,
    slidesPerView: 3,
    navigation: {
      nextEl: document.querySelector('.swiper-button-next--news'),
      prevEl: document.querySelector('.swiper-button-prev--news'),
    },
    spaceBetween: 30,
    initialSlide: 1,
    allowTouchMove: false,
    breakpoints: {
      768: {
        slidesPerView: 1,
        spaceBetween: 30,
        allowTouchMove: true,
      },
    }

});
}

const DepoimentosCarouselContainer = document.querySelector('[data-carousel="depoimentos"]');

if (DepoimentosCarouselContainer) {

  const DepoimentosCarousel = new Swiper(DepoimentosCarouselContainer, {
    speed: 400,
    pagination: {
      el: DepoimentosCarouselContainer.querySelector('.swiper-pagination'),
      clickable: true
    },
    spaceBetween: 0,
    autoHeight: true,
    autoplay: {
      delay: 5000,
    },


});
}

const JuradosDigitalContainer = document.querySelector('[data-carousel="jurados-digital"]');

if (JuradosDigitalContainer) {

  const JuradosDigCarrossel = new Swiper(JuradosDigitalContainer, {
    speed: 400,
    slidesPerView: 'auto',
    spaceBetween: 30,
    slidesPerGroup: 1,
    loop: false,
    navigation: {
      nextEl: document.querySelector('.swiper-button-next--digital'),
      prevEl: document.querySelector('.swiper-button-prev--digital')
    },
    breakpoints: {
      768: {
        slidesPerView: 2,
        spaceBetween: 15,
      },
    }
  });
}

const JuradosCampanhaContainer = document.querySelector('[data-carousel="campanha-programa"]');

if (JuradosCampanhaContainer) {

  const JuradosCamCarrossel = new Swiper(JuradosCampanhaContainer, {
    speed: 400,
    slidesPerView: 'auto',
    spaceBetween: 30,
    slidesPerGroup: 1,
    loop: false,
    navigation: {
      nextEl: document.querySelector('.swiper-button-next--campanha'),
      prevEl: document.querySelector('.swiper-button-prev--campanha')
    },
    breakpoints: {
      768: {
        slidesPerView: 2,
        spaceBetween: 15,
      },
    }
  });
}

const JuradosCrmCenterContainer = document.querySelector('[data-carousel="crm-loyalty"]');

if (JuradosCrmCenterContainer) {

  const JuradosCrmCarrossel = new Swiper(JuradosCrmCenterContainer, {
    speed: 400,
    slidesPerView: 'auto',
    spaceBetween: 30,
    slidesPerGroup: 1,
    loop: false,
    navigation: {
      nextEl: document.querySelector('.swiper-button-next--crm'),
      prevEl: document.querySelector('.swiper-button-prev--crm')
    },
    breakpoints: {
      768: {
        slidesPerView: 2,
        spaceBetween: 15,
      },
    }
  });
}

const JuradosCallCenterContainer = document.querySelector('[data-carousel="callcenter"]');

if (JuradosCallCenterContainer) {

  const JuradosCallCarrossel = new Swiper(JuradosCallCenterContainer, {
    speed: 400,
    slidesPerView: 'auto',
    spaceBetween: 30,
    slidesPerGroup: 1,
    loop: false,
    navigation: {
      nextEl: document.querySelector('.swiper-button-next--callcenter'),
      prevEl: document.querySelector('.swiper-button-prev--callcenter')
    },
    breakpoints: {
      768: {
        slidesPerView: 2,
        spaceBetween: 15,
      },
    }
  });
}

const CategoriasContainer = document.querySelector('[data-carousel="categorias"]');

if (CategoriasContainer) {

  const CategoriasCarrossel = new Swiper(CategoriasContainer, {
    speed: 400,
    slidesPerView: 1,
    spaceBetween: 30,
    slidesPerGroup: 1,
    loop: false,
    centeredSlides: true,
    navigation: {
      nextEl: CategoriasContainer.querySelector('.swiper-button-next'),
      prevEl: CategoriasContainer.querySelector('.swiper-button-prev')
    }
  });
}

const NoticiaContainer = document.querySelector('[data-carousel="noticia"]');

if (NoticiaContainer) {

  const NoticiaCarrossel = new Swiper(NoticiaContainer, {
    speed: 400,
    slidesPerView: 'auto',
    spaceBetween: 30,
    slidesPerGroup: 1,
    loop: false,
    navigation: {
      nextEl: NoticiaContainer.querySelector('.swiper-button-next'),
      prevEl: NoticiaContainer.querySelector('.swiper-button-prev')
    }
  });
}

var btMenu = document.querySelector('.masthead__toggle');
btMenu.onclick = function() {
  document.body.classList.add('has--nav');
}
var btFechar = document.querySelector('.masthead__icon-close');
btFechar.onclick = function() {
  document.body.classList.remove('has--nav');
}

// Validação de formulário de contato
let contactBtnSubmit = document.querySelector('.contato-article__btn');
if (contactBtnSubmit) {
    contactBtnSubmit.addEventListener('click', function(e) {
        let contactNameInput = document.querySelector('[name="name"]');
        let contactEmailInput = document.querySelector('[name="email"]');
        let contactMsgInput = document.querySelector('[name="msg"]');
        let contactMsgError = document.querySelector('.msg-error');

        let contactGroupName = contactNameInput.closest('.contato-article__group');
        let contactGroupEmail = contactEmailInput.closest('.contato-article__group');
        let contactGroupMsg = contactMsgInput.closest('.contato-article__group');

        contactGroupName.classList.remove('contato-article__group--error');
        contactGroupEmail.classList.remove('contato-article__group--error');
        contactGroupMsg.classList.remove('contato-article__group--error');
        contactMsgError.style.display = 'none';

        // Verifica se e-mail, nome e mensagem foram informados corretamente
        if (!contactNameInput.checkValidity() || !contactEmailInput.checkValidity() || !contactMsgInput.checkValidity()) {
            // Se não, exibe a mensagem de erro
            contactMsgError.style.display = 'block';

            if (!contactNameInput.checkValidity()) {
              contactGroupName.classList.add('contato-article__group--error');
            }

            if (!contactEmailInput.checkValidity()) {
              contactGroupEmail.classList.add('contato-article__group--error');
            }

            if (!contactMsgInput.checkValidity()) {
              contactGroupMsg.classList.add('contato-article__group--error');
            }
            
        } else {
            contactMsgError.style.display = 'none';
        }
    });
}

// Envio do formulario de contato
let contactForm = document.querySelector('.contato-article__form');
if (contactForm) {
  contactForm.addEventListener('submit', (e) => {            

    let form = e.target;

    if (form._submitting) return false;

    e.preventDefault();

    form._submitting = true;

    Array.from(form.querySelectorAll('[type="submit"]')).forEach(function(input) {
      input.disabled = true;
    });

    const method = form.method;
    const action = form.action;

    return Ajax({
      method, action, form
    })
    .then(content => {
      form._submitting = false;

      let status = content.status;
      let redirect = content.redirect;

      if ('dataLayer' in window && status == 'success') dataLayer.push({'event': 'clickEvent', 'eventCategory': 'Contato', 'eventAction': 'Formulario de Contato', 'eventLabel': 'Enviar'});


      if (redirect) {
        window.location.replace(redirect);
      }

      Array.from(form.querySelectorAll('[type="submit"]')).forEach(function(input) {
        input.removeAttribute('disabled');
      });

      return false;
    })
    .catch(err => {
      form._submitting = false;

      Array.from(form.querySelectorAll('[type="submit"]')).forEach(function(input) {
        input.removeAttribute('disabled');
      });

      console.error(err);
    })
  })
}

// Validação de formulário de inscrição (mesma mecânica do formulário de contato)
let subscribeBtnSubmit = document.querySelector('.inscreva-article__btn');
if (subscribeBtnSubmit) {
    subscribeBtnSubmit.addEventListener('click', function(e) {
        let subscribeNameInput = document.querySelector('[name="name"]');
        let subscribeEmailInput = document.querySelector('[name="email"]');
        let subscribeMsgError = document.querySelector('.msg-error');

        let subscribeGroupName = subscribeNameInput.closest('.inscreva-article__group');
        let subscribeGroupEmail = subscribeEmailInput.closest('.inscreva-article__group');

        subscribeGroupName.classList.remove('inscreva-article__group--error');
        subscribeGroupEmail.classList.remove('inscreva-article__group--error');
        subscribeMsgError.style.display = 'none';

        if (!subscribeNameInput.checkValidity() || !subscribeEmailInput.checkValidity()) {

          if ('dataLayer' in window) dataLayer.push({'event': 'clickEvent', 'eventCategory': 'Ficha de Pré Inscrição', 'eventAction': 'Cadastro Erro', 'eventLabel': 'Erro ao Enviar'});

          subscribeMsgError.style.display = 'block';

          if (!subscribeNameInput.checkValidity()) {
            subscribeGroupName.classList.add('inscreva-article__group--error');
          }

          if (!subscribeEmailInput.checkValidity()) {
            subscribeGroupEmail.classList.add('inscreva-article__group--error');
          }

        } else {
            subscribeMsgError.style.display = 'none';
        }
    });
}

// Envio do formulario de inscrição
let subscribeForm = document.querySelector('.inscreva-article__form');
if (subscribeForm) {
  subscribeForm.addEventListener('submit', (e) => {            

    let form = e.target;

    if (form._submitting) return false;

    e.preventDefault();

    form._submitting = true;

    Array.from(form.querySelectorAll('[type="submit"]')).forEach(function(input) {
      input.disabled = true;
    });

    const method = form.method;
    const action = form.action;

    return Ajax({
      method, action, form
    })
    .then(content => {
      form._submitting = false;

      let status = content.status;
      let redirect = content.redirect;

      if ('dataLayer' in window && status == 'success') dataLayer.push({'event': 'clickEvent', 'eventCategory': 'Ficha de Pré Inscrição', 'eventAction': 'Cadastro Sucesso', 'eventLabel': 'Enviar'});

      if (redirect) {
        window.location.replace(redirect);
      }

      Array.from(form.querySelectorAll('[type="submit"]')).forEach(function(input) {
        input.removeAttribute('disabled');
      });

      return false;
    })
    .catch(err => {
      form._submitting = false;

      Array.from(form.querySelectorAll('[type="submit"]')).forEach(function(input) {
        input.removeAttribute('disabled');
      });

      console.error(err);
    })
  })
}


//abas categorias

var tabList = document.querySelectorAll('.categorias-content__item');
var dropdown = document.querySelector('.categorias-content__dropdown');
var tabNav = document.querySelector('.categorias-content__nav');

if (tabNav) {
  for (var tabName of tabList) {
    tabName.addEventListener('click', function openCity(event, catName) {
      var catName = event.target.getAttribute('data-tab-name');
      var i, tabcontent, tablinks;
      var textoTab = event.target.innerHTML;
      tabNav.classList.remove('open');
      console.log(textoTab);
      dropdown.textContent=textoTab;

    // Get all elements with class="tabcontent" and hide them
    tabcontent = document.getElementsByClassName('categorias-content__grid');
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = 'none';
    }

    // Get all elements with class="tablinks" and remove the class "active"
    tablinks = document.getElementsByClassName('categorias-content__item');
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(' active', '');
    }

    // Show the current tab, and add an "active" class to the button that opened the tab
    document.getElementById(catName).style.display = 'grid';
    event.currentTarget.className += ' active';


    })
  }

  dropdown.onclick = function() { 
    tabNav.classList.toggle('open'); //toggle a classe .fechado

  }
}

var btScrollTop = document.getElementsByClassName('footer-info__btn');

btScrollTop.onclick = function() { 
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;

}

// Envio do formulario de download
let downloadEmailForm = document.querySelector('.download-email__form');
if (downloadEmailForm) {
  downloadEmailForm.addEventListener('submit', (e) => {            

    let form = e.target;

    if (form._submitting) return false;

    e.preventDefault();

    form._submitting = true;

    Array.from(form.querySelectorAll('[type="submit"]')).forEach(function(input) {
      input.disabled = true;
    });

    const method = form.method;
    const action = form.action;

    return Ajax({
      method, action, form
    })
    .then(content => {
      form._submitting = false;

      let status = content.status;
      let redirect = content.redirect;
      let category = content.category;

      if ('dataLayer' in window && status == 'success') dataLayer.push({'event': 'clickEvent', 'eventCategory': 'Download', 'eventAction': 'Digite seu e-mail de cadastro', 'eventLabel': 'Entrar'});
      
      if (redirect) {
        window.location.replace(redirect);
      }

      if (category) {
        // Gambs para forçar o downlaod no successo da verificação
        // Cria uma tag "a" genérica
        let downloadLink = document.createElement('a');
        // Instancia com o link de download
        downloadLink.href = base__url + '/download/download_file/' + category;
        // Instancia com o atributo "download"
        downloadLink.download = true;
        // Deixa oculto do site
        downloadLink.style.display = 'none';
        // Insere a tag no site
        document.body.appendChild(downloadLink);
        // Força o clique
        downloadLink.click();
      }

      Array.from(form.querySelectorAll('[type="submit"]')).forEach(function(input) {
        input.removeAttribute('disabled');
      });

      return false;
    })
    .catch(err => {
      form._submitting = false;

      Array.from(form.querySelectorAll('[type="submit"]')).forEach(function(input) {
        input.removeAttribute('disabled');
      });

      console.error(err);
    })
  })
}

// Envio da inscrição pela página de download
let subscribeDownloadForm = document.querySelector('.download-inscreva__form');
if (subscribeDownloadForm) {
  subscribeDownloadForm.addEventListener('submit', (e) => {            

    let form = e.target;

    if (form._submitting) return false;

    e.preventDefault();

    form._submitting = true;

    Array.from(form.querySelectorAll('[type="submit"]')).forEach(function(input) {
      input.disabled = true;
    });

    const method = form.method;
    const action = form.action;

    return Ajax({
      method, action, form
    })
    .then(content => {
      form._submitting = false;

      let status = content.status;
      let redirect = content.redirect;

      if ('dataLayer' in window && status == 'success') dataLayer.push({'event': 'clickEvent', 'eventCategory': 'Download', 'eventAction': 'Preencha os Campos Abaixo e Inscreva-se', 'eventLabel': 'Enviar'});

      if (redirect) {
        window.location.replace(redirect);
      }

      Array.from(form.querySelectorAll('[type="submit"]')).forEach(function(input) {
        input.removeAttribute('disabled');
      });

      return false;
    })
    .catch(err => {
      form._submitting = false;

      Array.from(form.querySelectorAll('[type="submit"]')).forEach(function(input) {
        input.removeAttribute('disabled');
      });

      console.error(err);
    })
  })
}

var videoGanhadores = document.querySelector('.home-ganhadores__figure');
if( videoGanhadores ){
  videoGanhadores.onclick = function() { 
    this.classList.add('is--playing');
  }
}
