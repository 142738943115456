export default function Ajax(options = {}) {
  return new Promise((resolve, reject) => {
    const method = options.method || this._form.getAttribute('method') || 'POST';
    let action = options.action || this._form.getAttribute('action') || location.pathname;
    let params = null;

    if (options.form) params = options.form instanceof FormData ? options.form : new FormData(options.form);

    if (options.appendData) {
      if (!params) params = new FormData();

      options.appendData.forEach((param) => {
        params.append(param.name, param.value);
      })
    }

    const req = new XMLHttpRequest();
    req.open(method, action, true);

    req.onload = () => {
      if (req.status >= 200 && req.status < 400) {
        return resolve(JSON.parse(req.responseText))
      } else {
        return reject('We reached our target server, but it returned an error')
      }
    }

    req.onerror = () => {
      return reject('There was a connection error of some sort')
    }

    req.send(params);
  });
}