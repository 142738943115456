const VMasker = require('vanilla-masker');

export const Masks = {
  init: function() {
    Array.from(document.querySelectorAll('[data-mask]')).forEach((input) => {
      switch (input.dataset.mask) {
        case 'cpf':
          this.cpf(input);
          break;
        case 'cnpj':
          this.cnpj(input);
          break;
        case 'tel':
          this.tel(input);
          break;
        case 'telshort':
          this.telshort(input);
          break;
        case 'doc':
          this.doc(input);
          break;
        case 'date':
          this.date(input);
          break;
        case 'zipcode':
          this.zipcode(input);
          break;
        case 'creditcard':
          this.creditcard(input);
          break;
        case 'datemes':
          this.datemes(input);
          break;
        case 'cvc':
          this.cvc(input);
          break;
        case 'ddd':
          this.ddd(input);
          break;
        case 'number':
          this.number(input);
          break;
        default:
          VMasker(input).maskPattern(input.dataset.mask);
      }
    });
  },
  creditcard: function(input) {
    VMasker(input).maskPattern('9999 9999 9999 9999');
  },
  cpf: function(input) {
    VMasker(input).maskPattern('999.999.999-99');
  },
  cnpj: function(input) {
    VMasker(input).maskPattern('99.999.999/9999-99');
  },
  zipcode: function(input) {
    VMasker(input).maskPattern('99999-999');
  },
  cvc: function(input) {
    VMasker(input).maskPattern('999');
  },
  ddd: function(input) {
    VMasker(input).maskPattern('99');
  },
  date: function(input) {
    VMasker(input).maskPattern('99/99/9999');
  },
  datemes: function(input) {
    VMasker(input).maskPattern('99/99');
  },
  number: function(input) {
    VMasker(input).maskNumber();
  },
  doc: function(input) {
    const docMask = ['999.999.999-999', '99.999.999/9999-99'];
    VMasker(input).maskPattern(docMask[0]);
    input.addEventListener('input', this.inputHandler.bind(undefined, docMask, 14), false);
  },
  tel: function(input) {
    const telMask = ['(99) 9999-99999', '(99) 99999-9999'];
    VMasker(input).maskPattern(telMask[0]);
    input.addEventListener('input', this.inputHandler.bind(undefined, telMask, 14), false);
  },
  telshort: function(input) {
    const telMask = ['9999-99999', '99999-9999'];
    VMasker(input).maskPattern(telMask[0]);
    input.addEventListener('input', this.inputHandler.bind(undefined, telMask, 9), false);
  },
  inputHandler: function(masks, max, event) {
    let c = event.target;
    let v = c.value.replace(/\D/g, '');
    let m = c.value.length > max ? 1 : 0;
    VMasker(c).unMask();
    VMasker(c).maskPattern(masks[m]);
    c.value = VMasker.toPattern(v, masks[m]);
  }
}
